import React from 'react'
import { PageProps } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
import PricingView from '../views/pricing.view.js'

const PricingPage: React.FC<PageProps> = () => {
  const { t } = useTranslation('custom')
  return (
    <Layout seo={{ webflow: '5fea46229870969ae6ba639f', title: t('Nos tarifs transparents') }}>
      <PricingView />
    </Layout>
  )
}

export default PricingPage
