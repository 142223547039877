
  import React from "react"
  import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

  import shape2 from "../images/shape-2.png"
import shape3 from "../images/shape-3.png"
import shape3P500 from "../images/shape-3-p-500.png"
import shape3P800 from "../images/shape-3-p-800.png"
import shape3P1080 from "../images/shape-3-p-1080.png"
import shape8 from "../images/shape-8.png"
import shape8P500 from "../images/shape-8-p-500.png"
import arrowTop from "../images/arrow-top.png"

  const PricingView = (props) => {
    const { t } = useTranslation()

    return (<div>
  <div id="Top" className="hero about _5 hide">
    <div className="container">
      <div className="hero-grid">
        <div className="div-block-4">
          <h1 className="display-1"><Trans>1 Offre</Trans><a href="http://marco-template.webflow.io/home-pages/home-1#Services"><strong><br /></strong></a>
          </h1>
          <h2 className="display-2"><Trans>Vous ne pouvez pas vous trompez</Trans><a href="http://marco-template.webflow.io/home-pages/home-1#Services"><strong><br /></strong></a>
          </h2>
          <div className="top-margin-30px" />
        </div>
      </div>
    </div><img src={shape2} loading="lazy" width={60} alt="shape2" className="scribble-1 sub" /><img src={shape3} loading="lazy" width={80} sizes="100vw" srcSet={`${shape3P500} 500w, ${shape3P800} 800w, ${shape3P1080} 1080w, ${shape3} 1200w`} alt="shape3" className="scribble-2 sub" />
  </div>
  <div id="Top" className="hero about _5 hide">
    <div className="container">
      <h1 className="heading-3"><Trans>1 offre unique</Trans><br /></h1>
    </div>
  </div>
  <div id="Team" className="section _0px-top wf-section">
    <div className="container w-container">
      <div className="collection-list-2">
        <div id="w-node-b7d713a1-d431-9a58-f553-cd5bc118d07b-e6ba639f" className="pricing-wrapper red-border">
          <div className="top-part">
            <h2 className="heading-3"><Trans>Une offre simple mais complète</Trans></h2>
            <div className="most-bought-badge"><Trans>SANS ENGAGEMENT</Trans></div>
          </div>
          <div className="bottom-part">
            <div className="top-margin-30px">
              <p className="pricing-paragraph pricing-body"><em><Trans>Nous avons conçu cette offre contenant tout le nécessaire pour vendre en ligne ! Tout ça pour 0&nbsp;euros !</Trans></em><br /></p>
              <p className="pricing-paragraph"><span className="icon-offer"> 🎨</span><Trans> &nbsp;Design Unique</Trans></p>
              <p className="pricing-paragraph"> &nbsp;<span className="icon-offer">🍹 </span><Trans>CMS simple d'utilisation</Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">🍾</span><Trans> &nbsp;Fiches catégories et produits illimités</Trans><br /></p>
              <p className="pricing-paragraph"><span className="icon-offer">⚡️</span><Trans> Site extrêmement rapide !</Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">💳</span><Trans> &nbsp;Paiement sécurisé </Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">🎁</span><Trans> &nbsp;Nom de domaine inclus</Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">🔒</span><Trans>&nbsp; Site sécurisé en HTTPS</Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">💸</span><Trans>&nbsp;&nbsp;Frais de port limité à 10 euros</Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">🚀</span><Trans> &nbsp;Mise en place et suivi de Google Ads</Trans></p>
              <p className="pricing-paragraph"><span className="icon-offer">📍</span><Trans> Rémunération exclusivement à la commission</Trans></p>
            </div>
          </div>
          <div className="price-only">
            <div className="badge yellow price-badge">0 €</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="section top-line wf-section">
    <div className="container">
      <div className="faq-grid">
        <div id="w-node-b2c60d7c-80a5-d402-029f-c361eaf42920-e6ba639f" className="tab-flex sticky">
          <div>
            <h4 className="display-2"><Trans>F.A.Q</Trans><a href="http://marco-template.webflow.io/home-pages/home-1#Services"><strong><br /></strong></a>
            </h4>
            <div className="top-margin-30px">
              <p className="sub-text"><Trans>Vous avez une autre question ?&nbsp;</Trans></p>
            </div>
            <div className="top-margin-50px">
              <Link to="/contact" className="primary-button green w-button"><Trans>Nous contacter</Trans></Link>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div>Est-ce que je peux avoir une adresse mail professionnelle ?&nbsp;Ex : contact@domaine.fr</div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Oui bien sûr nous proposons ce service.&nbsp;Nous pouvons même rediriger les mails arrivant à cette adresse vers votre email préféré !</Trans><br /></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>J'ai déjà un site, est-ce que je peux garder le même nom de domaine avec le nouveau ?&nbsp;</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Oui c'est possible et même conseillé ! Par ailleurs, si vous souhaitez changer de nom de domaine, nous pouvons aussi rediriger l'ancien domaine vers le nouveau domaine.</Trans><br /></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Est-ce l'hébergement et le nom de domaine sont inclus ?</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Oui l'hébergement est le nom de domaine sont inclus !</Trans><br /></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Que se passe t-il si je ne veux pas continuer avec vous &nbsp;?&nbsp;</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Si vous ne souhaitez pas l'accompagnement (hébergement, nom de domaine, HTTPS), vous êtes totalement libre de le faire.&nbsp;C'est une offre sans engagement. </Trans><br /><br /><Trans>Nous vous transmettrons l'intégralité de votre site, soit le HTML/CSS et JS, à vous ensuite de l'héberger par vos soins pour qu'il soit de nouveau en ligne. Par ailleurs nous ne pourrons plus assurer la gestion des éventuels bugs.</Trans><br /></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Comment fonctionne le paiement ?</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Nous travaillons avec notre partenaire Stripe, la solution de référence pour des paiements sécurisé sur internet.</Trans><br /><Trans>Une fois qu'un client effectue une transaction, vous la retrouvez 5 jours plus tard sur votre compte bancaire, et immédiatement sur l'interface.&nbsp;</Trans></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Que se passe t-il s'il y a un bug sur mon site ?</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Nous mettons toujours la qualité en avant. Si vous trouvez un bug nous vous le résolvons gratuitement.</Trans></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Qu'est-ce qu'un site responsive ?&nbsp;</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>On dit qu'un site web est responsive lorsqu'il s'adapte parfaitement à toute les tailles d'écrans.&nbsp;</Trans></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Qu'est-ce qu'un CMS ?</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Un CMS est une page web qui vous permet de gérer votre site &nbsp;pour ajouter ou modifier son contenu. Il vous donne la possibilité par exemple de mettre à jour les prix de vos articles, ou écrire des articles de blog.</Trans></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Comment puis-je faire connaître mon site&nbsp;?</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Outre le fait de communiquer de manière classique, via des emails, de la publicité, du bouche-à-oreille...&nbsp;</Trans><br /><br /><Trans>Pour attirer de nouveaux visiteurs, il faut apparaître en premier dans les résultats de recherche.&nbsp;Ce champ de domaine vaste et complexe se nomme le SEO.&nbsp;</Trans><br />‍<br /><Trans>Nous nous occupons de la partie technique pour que votre site soit le mieux référencé, et nous vous accompagnons pour qu'il arrive en premier grâce à la publicité (Google Ads).</Trans><br />‍</p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Qu'est-ce que Google Analytics ?&nbsp;</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Google Analytics vous permet d'obtenir des caractéristiques sur les visiteurs de votre site.&nbsp;Grâce à ces métriques, vous serez plus à même d'adapter votre stratégie à vos nouveaux clients.</Trans></p>
                </div>
              </div>
            </div>
            <div className="toggle-wrapper">
              <a href="#" className="toggle-header w-inline-block">
                <div><Trans>Comment se passe le design ?&nbsp;</Trans></div>
              </a>
              <div className="toggle-content">
                <div className="toggle-space">
                  <p className="paragraph"><Trans>Nous recueillons d'abord, toutes vos envies, vos caractéristiques, tout ce qui fait votre identité.&nbsp;</Trans><br />‍<br /><Trans>Puis les ressources inhérent à votre marque, son logo, ses couleurs, sa stratégie de communication, les images, textes que vous souhaitez voir apparaître. </Trans><br /><br /><Trans>Grâce à toutes ces informations nous sommes en mesure de vous présenter une maquette. Puis une fois que tout est validé nous passons à l'étape de développement.</Trans></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="wf-section">
    <div className="container">
      <div className="cta-wrapper">
        <div className="badge white"><Trans>Contact</Trans></div>
        <h4 className="display-2"><Trans>Vous avez un projet ?&nbsp;</Trans><br /><Trans>Parlons-en !</Trans></h4>
        <div className="top-margin-50px">
          <div className="meta-flex center">
            <Link to="/contact" className="primary-button green w-button"><Trans>Nous contacter</Trans></Link>
          </div>
        </div><img src={shape8} loading="lazy" width={120} sizes="(max-width: 479px) 70px, 120px" srcSet={`${shape8P500} 500w, ${shape8} 546w`} alt="shape8" className="scribble-4" /><img src={shape3} loading="lazy" width={60} sizes="(max-width: 479px) 100vw, 60px" srcSet={`${shape3P500} 500w, ${shape3P800} 800w, ${shape3P1080} 1080w, ${shape3} 1200w`} alt="shape3" className="scribble-2 left" />
      </div>
    </div>
  </div>
  <a href="#Top" style={{WebkitTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}} className="go-top w-inline-block"><img src={arrowTop} loading="lazy" width={256} alt="arrowTop" className="image" /></a>
</div>)
  };
  
  export default PricingView
  